import React from "react"
import { graphql} from "gatsby"
import Markdown from 'react-markdown'

import Layout from "../components/layout"
import SEO from "../components/seo";

import style from '../components/resources/resources.module.css';
import CatItem1 from '../../images/resources/cat-item-1.png'
import CatItem2 from '../../images/resources/cat-item-2.png'
import CatList from '../components/resources/categoriesList'
import RList from '../components/resources/resources-list'


const Resources = ({data}) => {
  let d = data.strapiCategories;
  return(
    <Layout>
        <SEO title={d.name}/>
        <section className={style.resourcesSection}>
          <h3 className={style.resourcesTitle}>Resources</h3>
          <div className={style.container}>
            <CatList style={style}/>
            <div className={style.box2}>
              <div className={style.box2Container}>
                <div className={style.resourcesCatItem}>
                  <img src={CatItem1} alt=""/>
                </div>
                <div className={style.resourcesCatItem}>
                  <img src={CatItem2} alt=""/>
                </div>
              </div>
            </div>
          </div>
          <RList style={style} r={d.resources}/>
        </section>
    </Layout>
  )
}

export default Resources

export const query = graphql`
  query CategoryTemplate($slug: String!) {
    strapiCategories(slug: { eq: $slug }) {
      id
      name
      resources {
        name
        slug
        excerpt
        id
      }
    }
  }
` 

