import React from 'react'
import {Link} from "gatsby"

const RList = ({style, r}) => {
	return(
		<div className={style.container1}>
		{
			r.map((item, index)=>{
				return(
					<div className={style.resourcesItem} key={`r_${index}`}>
						<h4 className={style.resourcesItemTitle} title={item.name.length > 37 ? item.name : ''}>
							{item.name.substring(0, 37)} {item.name.length > 37 ? '...' : ''}
						</h4>
						<div className={style.resourcesItemDesc}>
							<p className={style.resourcesP} title={item.excerpt.length > 120 ? item.excerpt : ''}>
								{item.excerpt.substring(0, 120)} {item.excerpt.length > 120 ? '...' : ''}
							</p>
						</div>
						<div className={style.viewMore}>
							<Link to={`resources/${item.slug}`}>
								VIEW ARTICLE
							</Link>
						</div>
					</div>
				)
			})
		}
		</div>
	)
}

export default RList