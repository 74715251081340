import React from 'react';
import {graphql, useStaticQuery, Link} from "gatsby"

const CatList = ({style}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiCategories {
			    edges {
			      node {
			      	name
			      	slug
			        resources {
			          name
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	let totalCount = 0;
	query.allStrapiCategories.edges.forEach(({node})=>{
		d.push(node);
		totalCount += node.resources.length;
	})
	return (
		<div className={style.box1}>
			<div className={style.resourcesCategories}>
				<div className={style.catItem}>
					<Link to="/resources" activeClassName={style.itemActive}> All Resources ({totalCount > 0 ? totalCount : 0})</Link>
				</div>
				{
					d.map((item, index)=>{
						return(
							<div className={style.catItem} key={`cat_${index}`}>
								<Link activeClassName={style.itemActive} to={`/category/${item.slug}`}>{item.name} ({item.resources.length}) </Link>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default CatList;